import Heading from "./Heading";
import CTA from "./CTA";
import Process from "./Process";
import Coordinators from "./Coordinators";
import { useInView } from "react-intersection-observer";

// imgs
import process_illustration from "../imgs/process_illustration.webp";
import support_illustration from "../imgs/support_illustration.webp";

const Support = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });
  return (
    <section className="pt-24 text-dark">
      {/* Support Co-ordination */}
      <article
        ref={myRef}
        className={`fade-in max-w-screen-2xl mx-auto mb-24 px-6 md:px-12 flex flex-col-reverse gap-12 lg:flex-row justify-evenly ${
          myElementIsVisible ? "appear" : ""
        }`}
      >
        <section className="lg:w-3/6 space-y-6 my-auto">
          <h1 className="font-bold text-3xl md:text-4xl">
            Support Co-ordination
          </h1>
          <p>
            NDIS (National Disability Insurance Scheme) support coordination is
            a vital service that assists individuals with disabilities in
            navigating and maximizing their benefits from the NDIS. Support
            coordination aims to help participants understand their NDIS plan,
            identify their goals and needs, and connect with suitable service
            providers and community resources to achieve their desired outcomes.
            One of the key roles of NDIS support coordinators is to work closely
            with participants to develop and implement their NDIS plans. This
            includes conducting assessments, understanding the participant's
            unique circumstances, and collaboratively creating a plan that
            aligns with their goals and aspirations. Support coordinators also
            assist participants in finding and connecting with appropriate
            service providers, coordinating appointments, managing appointments,
            and facilitating communication between different service providers
            to ensure a coordinated approach to their care.
          </p>
        </section>

        <section className="lg:w-2/6 my-auto">
          <img src={support_illustration} alt="illustration" className="" />
        </section>
      </article>

      {/* What is Support Co-ordination */}
      <Heading heading="What is Support Co-ordination" />

      <article className="max-w-screen-2xl my-12 md:my-24 mx-auto px-6 md:px-12 flex flex-col items-center justify-evenly space-y-12">
        <section className="">
          <p className="text-dark">
            In addition to plan development and service provider coordination,
            NDIS support coordination also focuses on building the capacity and
            independence of participants. This includes assisting participants
            in developing skills and knowledge to self-manage their NDIS plans,
            make informed decisions, and exercise choice and control over their
            supports. Support coordinators also provide ongoing support and
            advocacy, helping participants to resolve issues or challenges that
            may arise during the implementation of their NDIS plans.
            <br />
            <br />
            Overall, NDIS support coordination plays a crucial role in helping
            individuals with disabilities effectively access and utilize their
            NDIS funding, achieve their goals, and enhance their overall
            well-being and quality of life. Through personalized support and
            coordination, NDIS support coordinators enable participants to make
            informed choices, build their capacity, and navigate the
            complexities of the NDIS system to achieve their desired outcomes.
          </p>
        </section>
        <section className="space-y-12">
          <h2 className="text-xl md:text-2xl font-bold mb-6 text-left">
            As your Support Coordinator, My Disability Partner will help you:
          </h2>

          <div className="space-y-8 text-left text-dark">
            <div className="text-dark/80 lg:text-lg list-none space-y-4 md:space-y-0 md:grid grid-cols-3 items-start justify-items-stretch gap-8">
              <li className="flex gap-2">
                <i className="fa-solid fa-square-check text-green-500"></i>{" "}
                <p className="-translate-y-1">
                  Education and a breakdown of your NDIS funding.
                </p>
              </li>
              <li className="flex gap-2">
                <i className="fa-solid fa-square-check text-green-500"></i>{" "}
                <p className="-translate-y-1">Monitoring and reporting.</p>
              </li>
              <li className="flex gap-2">
                <i className="fa-solid fa-square-check text-green-500"></i>{" "}
                <p className="-translate-y-1">Communicating with providers.</p>
              </li>
              <li className="flex gap-2">
                <i className="fa-solid fa-square-check text-green-500"></i>{" "}
                <p className="-translate-y-1">Helping in a crisis.</p>
              </li>
              <li className="flex gap-2">
                <i className="fa-solid fa-square-check text-green-500"></i>{" "}
                <p className="-translate-y-1">Reviewing your plan.</p>
              </li>
              <li className="flex gap-2">
                <i className="fa-solid fa-square-check text-green-500"></i>{" "}
                <p className="-translate-y-1">
                  Offering a person-centered service.
                </p>
              </li>
              <li className="flex gap-2">
                <i className="fa-solid fa-square-check text-green-500"></i>{" "}
                <p className="-translate-y-1">
                  Managing budgets and providing transparent feedback.
                </p>
              </li>
              <li className="flex gap-2">
                <i className="fa-solid fa-square-check text-green-500"></i>{" "}
                <p className="-translate-y-1">
                  Building skills to help manage your own supports over time.
                </p>
              </li>
              <li className="flex gap-2">
                <i className="fa-solid fa-square-check text-green-500"></i>{" "}
                <p className="-translate-y-1">
                  Provide implementation and connecting you to reputable
                  providers.
                </p>
              </li>
              <li className="flex gap-2">
                <i className="fa-solid fa-square-check text-green-500"></i>{" "}
                <p className="-translate-y-1">
                  Supporting with plan renewals and reassessments.
                </p>
              </li>
              <li className="flex gap-2">
                <i className="fa-solid fa-square-check text-green-500"></i>{" "}
                <p className="-translate-y-1">
                  Navigating the NDIS portal and systems.
                </p>
              </li>
            </div>
          </div>
        </section>
      </article>

      {/* Support Co-ordinations */}
      <Coordinators />

      {/* Call To action */}
      <article className="max-w-screen-2xl mb-24 md:my-24 mx-auto px-6 md:px-12 space-y-6 flex flex-col">
        <h3 className="font-semibold text-center text-3xl md:text-4xl lg:text-4xl text-dark">
          Simplify Your Life - Get Expert Help{" "}
          <br className="hidden md:block" /> Finding the Right Support
        </h3>
        <a
          href="mailto:caroline@mdpartner.com.au"
          className="font-semibold w-full md:w-2/6 mx-auto py-4 bg-primary hover:bg-secondary text-center text-sm lg:text-base tracking-wide text-light hover:text-neutral transition-all rounded-full inline-block"
        >
          Send us an email!
        </a>
      </article>

      {/* How To Process  */}
      <div className="py-6">
        <Heading heading="How to Process" />
      </div>

      <article className="mx-auto px-6 md:px-12">
        <p className="md:w-5/6 xl:w-3/6 mx-auto text-center text-dark/80">
          My Disability Partner Pty Ltd is an NDIS registered and experienced
          NDIS plan management company for people with disabilities. We deliver
          high-quality services to lead an independent life by tailoring your
          NDIS experience so you can get the most out of your plan and your
          life.
        </p>

        <section className="max-w-screen-xl mx-auto my-24 lg:flex items-start justify-evenly">
          <div className="lg:sticky top-32 md:w-4/6 mx-auto lg:w-2/6">
            <img src={process_illustration} alt="illustration" />
          </div>

          <div className="md:w-4/6 mx-auto">
            <Process
              count="01"
              heading="Do you have NDIS Plan Management Funding?"
              para="The NDIA will provide funding in your plan to pay for a Plan Manager. The Plan Manager  pays your providers for you, helps you keep track of funds and takes care of financial reporting for you.  This funding comes under the heading in your NDIS Plan as “Improved Life Choices”.
              If your NDIS Plan has Improved Life Choices built funding that is great news and you can work with My Disability Partner for Plan Management Services."
            />

            <Process
              count="02"
              heading="Join My Disability Partner"
              para="Once your NDIS plan has funding for Improved Life Choices, you can join our team at My Disability Partner.
              At My Disability Partner one of our highly qualified plan managers are more than happy to come see you face to face and discuss your NDIS plan and how you can achieve the best outcome for you and your NDIS Plan."
            />

            <Process
              count="03"
              heading="Notify your providers"
              para="It’s now time to inform all you service providers of the new change to your NDIS Plan and Plan Management services.
              My Disability Partner has made this easy:
              Using our contact cards, you can inform your service providers of the change in plan management services and the information they will need to send through their invoices.
              My Disability Partner is more than happy to contact all your service providers for you on your behalf and the information they will need moving forward
              Once your providers have all the information then you can sit back and allow My Disability Partner to manage the rest.
              Reimbursements can be made to you
              If you have paid for a service yourself, you can receive the funds directly back into your account. My Disability Partner will need a copy of the receipt which will show the amount, date of payment and this can be processed back to you."
            />

            <Process
              count="04"
              heading="Monitor your funding"
              para="At My Disability Partner you can download an app on your smartphone called Care view Advantage.
              The Care view Advantage App provides you with 24/7 access to:
              Current funding balances
              Let you view the invoice
              The Care view Advantage App will also:
              Send you a notification when a new invoice from your provider has been received
              Allow you to approve or decline an invoice – this will pause the payment straight away giving you time to contact us to assist on helping sort out your concerns or issues. We can also touch base with the provider on your behalf."
            />
          </div>
        </section>
      </article>

      {/* Call To action */}
      <CTA />
    </section>
  );
};

export default Support;
