import { useInView } from "react-intersection-observer";

const Process = ({ count, heading, para }) => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });

  return (
    <section
      ref={myRef}
      className={`fade-in mt-12 space-y-6 md:w-5/6 mx-auto ${
        myElementIsVisible ? "appear" : ""
      }`}
    >
      <span className="bg-secondary inline-block p-4 rounded-xl font-bold text-light text-xl">
        {count}
      </span>
      <div className="font-bold text-2xl">{heading}</div>
      <div className="">{para}</div>
    </section>
  );
};

export default Process;
