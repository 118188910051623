import { useInView } from "react-intersection-observer";
import Heading from "./Heading";
import CTA from "./CTA";

const Feedback = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });

  return (
    <section className="text-dark pt-12 px-8">
      <article className="max-w-screen-2xl mx-auto py-12 text-center space-y-12">
        <Heading heading="Give is a Feedback" />
      </article>

      <div className=" max-w-screen-2xl mx-auto py-12 flex gap-2 lg:gap-24 flex-col-reverse md:flex-row">
        <ul className="w-full px-8 md:w-3/6 mx-auto list-disc space-y-3">
          <li>
            <span className="font-bold">Assistance Avai </span>lable: We're here
            to support you in filing a complaint.
          </li>

          <li>
            <span className="font-bold">Complaint Submi </span>ssion Form: Use
            this form for a structured way to lodge a complaint with us.
          </li>

          <li>
            <span className="font-bold">Direct Communic </span>ation: You may
            opt to speak directly with a Manager or a staff member you prefer.
          </li>

          <li>
            <span className="font-bold">Confidentiality </span> Assurance: All
            submitted information remains strictly confidential.
          </li>

          <li>
            <span className="font-bold">Support Offered </span>: If you need
            assistance or have questions about the form, our admin team is ready
            to help.
          </li>

          <li>
            <span className="font-bold">Written Complai </span>nts Preferred: We
            encourage the submission of complaints in written form for clarity.
          </li>

          <li>
            <span className="font-bold">Response Timefr </span>ame: Expect a
            response within a maximum of ten (10) days.
          </li>

          <li>
            <span className="font-bold">Documentation: </span>Attach copies of
            any relevant documents to support your complaint; please retain the
            originals.
          </li>

          <li>
            <span className="font-bold">Further Actions </span>: Should you
            choose to escalate your complaint to the NDIS commission,
            <a href="tel:1800035544" className="font-bold block">
              Call Us : 1800 035 544
            </a>
          </li>
        </ul>

        <form
          action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSeE91S9QeAthcaGvKVjQd-p9d1ILmaNOpyOvYUpzFQUzi6jzw/formResponse"
          className="w-full md:w-3/6 max-w-screen-lg grid sm:grid-cols-2 gap-4 mx-auto px-4 pb-24"
        >
          <div className="">
            <label
              htmlFor="name"
              className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
            >
              Name
            </label>
            <input
              name="entry.1034670599"
              id="name"
              className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
              required
            />
          </div>

          <div className="">
            <label
              htmlFor="email"
              className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
            >
              Email*
            </label>
            <input
              name="entry.322283763"
              id="email"
              className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
              required
            />
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="disability"
              className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
            >
              Message*
            </label>
            <textarea
              name="entry.1564322037"
              id="disability"
              className="w-full h-44 bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
            ></textarea>
          </div>

          <div className="sm:col-span-2 flex justify-between items-center">
            <button
              type="submit"
              className="inline-block bg-secondary hover:bg-secondary/70 focus-visible:ring ring-indigo-300 text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-16 py-3"
            >
              Submit
            </button>

            <span className="text-gray-500 text-sm">*Required</span>
          </div>
        </form>
      </div>

      {/* Call To action */}
      <CTA />
    </section>
  );
};

export default Feedback;
