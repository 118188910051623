import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import Route from "./Route";
import Navbar from "./Navbar";
import Home from "./Home";
import About from "./About";
import Plan from "./Plan";
import TermsAndConditions from "./TermsAndConditions";
import Policy from "./Policy";
import Contact from "./Contact";
import Funding from "./Funding";
// import Blogs from "./Blogs";
import Support from "./Support";
import ScrollToTop from "./ScrollToTop";
import Feedback from "./Feedback";

import "../index.css";
import Footer from "./Footer";
import OurLocation from "./OurLocation";

const App = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 350);
    });
  }, []);

  return (
    <main className="font-satoshi">
      <BrowserRouter>
        <header id="navbar" className="">
          <div className="max-w-screen-2xl mx-auto">
            <ScrollToTop />
            <Navbar />
          </div>
        </header>

        <a
          id="fixCta"
          href="tel:+611300110251"
          className={
            scroll
              ? "text-light fixed z-30 top-24 md:top-44 -right-2 md:-right-2 px-6 py-3 font-medium lg:font-semibold text-sm tracking-wider rounded-xl shadow-xl transition-all"
              : "hidden"
          }
        >
          Call Us
        </a>

        {/* Navigation Routes */}
        <div className="">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
          <section>
            <div className="bg-dark w-full py-8 md:py-12"></div>
            <Switch>
              <Route path="/about">
                <About />
              </Route>

              <Route path="/plan">
                <Plan />
              </Route>

              <Route path="/support">
                <Support />
              </Route>

              <Route path="/funding">
                <Funding />
              </Route>

              <Route path="/contact">
                <Contact />
              </Route>

              <Route path="/terms-condition">
                <TermsAndConditions />
              </Route>

              <Route path="/privacy-policy">
                <Policy />
              </Route>

              {/* <Route path="/blogs">
                <Blogs />
              </Route> */}

              <Route path="/location">
                <OurLocation />
              </Route>

              <Route path="/feedback">
                <Feedback />
              </Route>
            </Switch>
          </section>
        </div>
        <Footer />
      </BrowserRouter>
    </main>
  );
};

export default App;
