import { useInView } from "react-intersection-observer";

// imgs
import mdp_vector from "../imgs/mdp_vector.svg";

const ChooseUs = ({ heading, text }) => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });
  return (
    <div
      ref={myRef}
      className={`fade-in bg-primary/20 p-8 lg:p-12 rounded-xl flex flex-col items-end ${
        myElementIsVisible ? "appear" : ""
      }`}
    >
      <img src={mdp_vector} alt="mdp vector" className="w-16 object-right" />
      <div className="space-y-6">
        <h2 className="font-semibold text-lg">{heading}</h2>
        <p className="">{text}</p>
      </div>
    </div>
  );
};

export default ChooseUs;
