import { useInView } from "react-intersection-observer";

const Testimonial = ({ text, img, name }) => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });
  return (
    <article
      ref={myRef}
      className={`fade-in text-dark space-y-8 p-6 sm:w-5/6 md:w-4/6 lg:w-5/6 mx-auto ${
        myElementIsVisible ? "appear" : ""
      }`}
    >
      <div className="flex items-center gap-4">
        <img
          src={img}
          alt="client"
          className="w-16 h-16 rounded-full object-cover object-top"
        />
        <div>
          <h2 className="font-cta font-bold text-2xl text-secondary">{name}</h2>
        </div>
      </div>

      <p className="text-dark/70">{text}</p>
    </article>
  );
};

export default Testimonial;
