import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../imgs/logo.svg";

const Navbar = () => {
  const hamburgerClick = () =>
    document.querySelector("#mobile_menu").classList.toggle("hidden");

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20);
    });
  }, []);

  return (
    <section className="text-light">
      <nav
        id="navbar"
        className={
          scroll
            ? "bg-dark fixed w-full left-0 px-8 md:px-10 lg:px-16 xl:px-24 py-4 z-40 shadow-lg shadow-primary/10 duration-300 border-b-2 border-primary transition-all"
            : "fixed w-full left-0 px-8 md:px-10 lg:px-16 xl:px-24 py-4 md:py-6 z-40 transition-all"
        }
      >
        <div className="flex justify-between items-center text-xs lg:text-sm">
          <section className="flex gap-12 xl:gap-24 items-center justify-between">
            <Link to="/">
              <img
                src={logo}
                alt=""
                className={`w-24 md:w-32 xl:w-40 cursor-pointer 
                ${scroll ? "scale-110" : "scale-125"} transition-transform`}
              />
            </Link>

            <aside id="desktop_menu" className="hidden lg:block text-light">
              <div className="font-regular list-none flex xl:gap-2">
                <Link
                  to="/"
                  className="py-2 px-2 md:px-4 cursor-pointer text-neutral hover:text-light/70 hover:underline underline-offset-4 transition-colors"
                >
                  Home
                </Link>
                <Link
                  to="/about"
                  className="py-2 px-2 md:px-4 cursor-pointer text-neutral hover:text-light/70 hover:underline underline-offset-4 transition-colors"
                >
                  About Us
                </Link>
                <Link
                  to="/plan"
                  className="py-2 px-2 md:px-4 cursor-pointer text-neutral hover:text-light/70 hover:underline underline-offset-4 transition-colors"
                >
                  Plan Management
                </Link>
                <Link
                  to="/support"
                  className="py-2 px-2 md:px-4 cursor-pointer text-neutral hover:text-light/70 hover:underline underline-offset-4 transition-colors "
                >
                  Support Co-ordination
                </Link>
                {/* <Link
                  to="/blogs"
                  className="py-2 px-2 md:px-4 cursor-pointer text-neutral hover:text-light/70 hover:underline underline-offset-4 transition-colors "
                >
                  Blogs
                </Link> */}
                <Link
                  to="/location"
                  className="py-2 px-2 md:px-4 cursor-pointer text-neutral hover:text-light/70 hover:underline underline-offset-4 transition-colors "
                >
                  Our Location
                </Link>
              </div>
            </aside>
          </section>

          <section className="flex items-center gap-6">
            <Link to="/contact">
              <button className="font-medium lg:font-bold w-24 lg:w-36 xl:w-44 py-2 lg:py-3 bg-primary hover:bg-secondary text-xs lg:text-sm text-light hover:text-neutral transition-all rounded-full">
                Contact Us
              </button>
            </Link>

            <i
              id="myBtn"
              className="fa-sharp fa-solid fa-bars lg:hidden text-light text-xl text-neutral cursor-pointer"
              onClick={hamburgerClick}
            ></i>
          </section>
        </div>
      </nav>

      {/* Mobile Nav */}
      <aside
        id="mobile_menu"
        className="bg-dark hidden md:hidden fixed w-full top-14 right-0 py-6 z-30"
      >
        <div className="space-y-8">
          <div
            onClick={hamburgerClick}
            className="px-6 font-normal text-neutral text-center text-lg list-none flex flex-col"
          >
            <Link
              to="/"
              className="py-4 cursor-pointer hover:text-light/80 transition-colors"
            >
              Home
            </Link>
            <Link
              to="/about"
              className="py-4 cursor-pointer hover:text-light/80 transition-colors"
            >
              About
            </Link>
            <Link
              to="/plan"
              className="py-4 cursor-pointer hover:text-light/80 transition-colors"
            >
              Plan Management
            </Link>
            <Link
              to="/support"
              className="py-4 cursor-pointer hover:text-light/80 transition-colors"
            >
              Support Co-ordination
            </Link>
            {/* <Link
              to="/blogs"
              className="py-4 cursor-pointer hover:text-light/80 transition-colors"
            >
              Blogs
            </Link> */}
            <Link
              to="/location"
              className="py-4 cursor-pointer hover:text-light/80 transition-colors"
            >
              Our Location
            </Link>

            {/* <Link href="/contact">
              <button className="font-bold mt-6 w-full bg-primary text-xs lg:text-sm uppercase px-6 lg:px-8 py-4 text-light hover:text-neutral hover:scale-105 transition-all rounded-full">
                Contact Us
              </button>
            </Link> */}
          </div>
        </div>
      </aside>
    </section>
  );
};

export default Navbar;
