import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const Services = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });
  return (
    <section
      ref={myRef}
      className={`fade-in max-w-screen-2xl mx-auto px-6 ${
        myElementIsVisible ? "appear" : ""
      }`}
    >
      <section className="smw-4/6 mdw-3/6 lg:w-auto mx-auto rounded-xl space-y12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-12">
        <article className="blog_container bg-zinc-100 flex flex-col lg:flex-row gap-6 rounded-lg overflow-hidden hover:cursor-pointer">
          <img
            src="https://images.pexels.com/photos/3824768/pexels-photo-3824768.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Blog Poster"
            className="blog_img lg:w-5/12 object-cover inset-0 transition duration-200"
            width="1200"
            height="800"
          />

          <div className="p-4 md:p-12 flex flex-col justify-evenly space-y-10">
            <div className="space-y-6">
              <h2 className="blog_heading font-bold text-xl lg:text-2xl transition-colors">
                NDIS Planning & Management
              </h2>
              <p className="text-sm md:text-base text-dark/80">
                The National Disability Insurance Scheme (NDIS) is a /
                government-funded program that provides support and funding to
                people with disabilities in Australia. NDIS plan management
                refers to a service provided by certain registered NDIS service
                providers known as plan managers.{" "}
              </p>
            </div>
            <div className="md:space-x-4">
              <Link to="/contact">
                <div className="font-semibold w-5/6 md:w-4/12 py-3 bg-primary hover:bg-secondary border-2 border-transparent text-center text-sm lg:text-base tracking-wide text-light hover:text-neutral transition-all rounded-full inline-block">
                  Register
                </div>
              </Link>
              <Link to="/plan">
                <div className="font-semibold w-5/6 md:w-4/12 mt-4 lg:mt-0 py-3 hover:bg-primary/10 text-center text-sm lg:text-base tracking-wide text-primary border-2 border-primary hover:text-neutral transition-all rounded-full inline-block">
                  View Service
                </div>
              </Link>
            </div>
          </div>
        </article>

        <article className="blog_container bg-zinc-100 flex flex-col lg:flex-row-reverse gap-6 rounded-lg overflow-hidden hover:cursor-pointer">
          <img
            src="https://images.pexels.com/photos/7551686/pexels-photo-7551686.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Blog Poster"
            className="blog_img lg:w-5/12 object-cover inset-0 transition duration-200"
            width="2016"
            height="1672"
          />

          <div className="p-4 md:p-12 flex flex-col justify-evenly space-y-10">
            <div className="space-y-6">
              <h2 className="blog_heading font-bold text-xl lg:text-2xl transition-colors">
                Support Co-ordination
              </h2>
              <p className="text-sm md:text-base text-dark/80">
                NDIS (National Disability Insurance Scheme) support coordination
                is a vital service that assists individuals with disabilities in
                navigating and maximizing their benefits from the NDIS. Support
                coordination aims to help participants understand their NDIS
                plan, identify their goals and needs, and connect with suitable
                service providers and community resources to achieve their
                desired outcomes.{" "}
              </p>
            </div>
            <div className="md:space-x-4">
              <Link to="/contact">
                <div className="font-semibold w-5/6 md:w-4/12 py-3 bg-primary hover:bg-secondary border-2 border-transparent text-center text-sm lg:text-base tracking-wide text-light hover:text-neutral transition-all rounded-full inline-block">
                  Register
                </div>
              </Link>
              <Link to="/support">
                <div className="font-semibold mt-4 lg:mt-0 w-5/6 md:w-4/12 py-3 hover:bg-primary/10 text-center text-sm lg:text-base tracking-wide text-primary border-2 border-primary hover:text-neutral transition-all rounded-full inline-block">
                  View Service
                </div>
              </Link>
            </div>
          </div>
        </article>
      </section>
    </section>
  );
};

export default Services;
