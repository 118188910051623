import { useInView } from "react-intersection-observer";

import faq_illustration from "../imgs/faq_illustration.webp";

const FAQ = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });

  return (
    <article
      ref={myRef}
      className={`fade-in max-w-screen-2xl my-24 mx-auto px-6 md:px-12 space-y-24 flex flex-col md:flex-row justify-center ${
        myElementIsVisible ? "appear" : ""
      }`}
    >
      <div className="md:w-3/6">
        <img
          src={faq_illustration}
          alt="illustration"
          className="md:w-4/6 mx-auto"
        />
      </div>

      <section className="md:w-3/6 space-y-12">
        <div className="space-y-4 mx-auto">
          <h2 className="font-bold text-xl md:text-2xl">
            Can you help me on the weekend or after hours?
          </h2>
          <p className="text-dark/80 md:text-lg">
            Yes. We visit in the evenings, can arrange overnight or 24 hour
            care, and provide services 7 days a week, 365 days a year.
          </p>
        </div>

        <div className="space-y-4 mx-auto">
          <h2 className="font-bold text-xl md:text-2xl">
            How long does it take for services to start?
          </h2>
          <p className="text-dark/80 md:text-lg">
            If you need help in a hurry we can organise services to start the
            next day, however most services start within two to five days to
            make sure we can find the right staff members for you. The team at
            your local service centre will be able to provide more information
            based on your particular situation and requirements.
          </p>
        </div>

        <div className="space-y-4 mx-auto">
          <h2 className="font-bold text-xl md:text-2xl">
            What happens if there is a medical emergency?
          </h2>
          <p className="text-dark/80 md:text-lg">
            Our in-home care team members are trained in first aid and basic
            life support to assist you until an ambulance arrives. We have an
            after hours service that can respond to any situation that may occur
            overnight or on weekends.
          </p>
        </div>
      </section>
    </article>
  );
};

export default FAQ;
