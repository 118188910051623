import { useInView } from "react-intersection-observer";

const Timeline = ({ heading, text, img }) => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });
  return (
    <li ref={myRef} className={`fade-in ${myElementIsVisible ? "appear" : ""}`}>
      <section className="timeline-content mx-auto">
        <article className="flex gap-4 rounded-b-xl border-t-4 border-secondary shadow-xl">
          <img
            src={img}
            alt="img"
            className="w-32 md:w-2/6 object-cover rounded-bl-xl shadow-xl"
          />
          <div className="space-y-4 py-6 px-2 my-auto">
            <h2 className="text-xl font-bold">{heading}</h2>
            <p className="text-xs sm:text-sm text-dark/90 font-medium">
              {text}
            </p>
          </div>
        </article>
      </section>
    </li>
  );
};

export default Timeline;
