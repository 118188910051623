import Heading from "./Heading";
import Button from "./Button";

const Policy = () => {
  return (
    <section className="px-8 pt-12">
      <div className="max-w-screen-2xl mx-auto py-12 space-y-6">
        <Heading heading="Privacy Policy" />
      </div>

      <article className="max-w-7xl mx-auto px-6 pt-12 pb-24 text-lg space-y-12">
        <div className="space-y-4">
          <header className="font-bold text-2xl">
            My Disability Partner Pty Ltd
          </header>

          <p>
            is committed to providing quality services to you and this policy
            outlines our ongoing obligations to you in respect of how we manage
            your Personal Information. We have adopted the Australian Privacy
            Principles (APPs) contained in the Privacy Act 1988 (Cth) (the
            Privacy Act). The NPPs govern the way in which we collect, use,
            disclose, store, secure and dispose of your Personal Information. A
            copy of the Australian Privacy Principles may be obtained from the
            website of The Office of the Australian Information Commissioner at
            www.aoic.gov.au
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">
            What is Personal Information and why do we collect it?
          </header>

          <p>
            Personal Information is information or an opinion that identifies an
            individual. Examples of Personal Information we collect include:
            names, addresses, email addresses, phone and facsimile numbers.
            <br />
            <br />
            This Personal Information is obtained in many ways including
            interviews, correspondence, by telephone and facsimile, by email,
            via our website www.mdpartner.com.au, from your website, from media
            and publications, from other publicly available sources, from
            cookies- delete all that aren’t applicable and from third parties.
            We don’t guarantee website links or policy of authorised third
            parties.
            <br />
            <br />
            We collect your Personal Information for the primary purpose of
            providing our services to you, providing information to our clients
            and marketing. We may also use your Personal Information for
            secondary purposes closely related to the primary purpose, in
            circumstances where you would reasonably expect such use or
            disclosure. You may unsubscribe from our mailing/marketing lists at
            any time by contacting us in writing.
            <br />
            <br />
            When we collect Personal Information we will, where appropriate and
            where possible, explain to you why we are collecting the information
            and how we plan to use it.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">Sensitive Information</header>

          <p>
            Sensitive information is defined in the Privacy Act to include
            information or opinion about such things as an individual’s racial
            or ethnic origin, political opinions, membership of a political
            association, religious or philosophical beliefs, membership of a
            trade union or other professional body, criminal record or health
            information.
            <br />
            <br />
            <span className="font-bold block">
              Sensitive information will be used by us only:
            </span>
            For the primary purpose for which it was obtained For a secondary
            purpose that is directly related to the primary purpose With your
            consent; or where required or authorised by law.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">Third Parties</header>

          <p>
            Where reasonable and practicable to do so, we will collect your
            Personal Information only from you. However, in some circumstances
            we may be provided with information by third parties. In such a case
            we will take reasonable steps to ensure that you are made aware of
            the information provided to us by the third party.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">
            Disclosure of Personal Information
          </header>

          <p>
            Your Personal Information may be disclosed in a number of
            circumstances including the following:
            <div>
              <li>Third parties where you consent to the use or disclosure</li>
              <li>Where required or authorised by law.</li>
            </div>
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">
            Security of Personal Information
          </header>

          <p>
            Your Personal Information is stored in a manner that reasonably
            protects it from misuse and loss and from unauthorized access,
            modification or disclosure.
            <br />
            <br />
            When your Personal Information is no longer needed for the purpose
            for which it was obtained, we will take reasonable steps to destroy
            or permanently de-identify your Personal Information. However, most
            of the Personal Information is or will be stored in client files
            which will be kept by us for a minimum of 7 years.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">
            Access to your Personal Information
          </header>

          <p>
            You may access the Personal Information we hold about you and to
            update and/or correct it, subject to certain exceptions. If you wish
            to access your Personal Information, please contact us in
            writing.end that you have JavaScript enabled in your browser
            settings. To enable JavaScript in your browser, follow the
            instructions for your browser version here.
            <br />
            <br />
            My Disability Partner Pty Ltd will not charge any fee for your
            access request, but may charge an administrative fee for providing a
            copy of your Personal Information. In order to protect your Personal
            Information we may require identification from you before releasing
            the requested information.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">
            Maintaining the Quality of your Personal Information
          </header>

          <p>
            It is an important to us that your Personal Information is up to
            date. will take reasonable steps to make sure that your Personal
            Information is accurate, complete and up-to-date. If you find that
            the information we have is not up to date or is inaccurate, please
            advise us as soon as practicable so we can update our records and
            ensure we can continue to provide quality services to you.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">Policy Updates</header>

          <p>
            This Policy may change from time to time and is available on our
            website.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">
            Privacy Policy Complaints and Enquiries
          </header>

          <p>
            If you have any queries or complaints about our Privacy Policy
            please contact us at:
            <br />
            <span className="font-bold">
              20 Macquarie Street Parramatta NSW 2150
            </span>
          </p>
        </div>

        <div className="flex flex-col gap-4 md:block md:space-x-4">
          <Button href="mailto:invoices@mdpartner.com.au" text="Mail Us" />
          <Button href="tel:+611300110251" text="Call Us" />
        </div>
      </article>
    </section>
  );
};

export default Policy;
