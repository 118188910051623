import Heading from "./Heading";
import Services from "./Services";
import { useInView } from "react-intersection-observer";

const OurLocation = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="max-w-screen-2xl mx-auto my-24 space-y-12">
      <Heading heading="Our Location" />
      <div
        ref={myRef}
        className={`fade-in mx-auto rounded-xl overflow-hidden mt-10 py-10 px-6 flex flex-col gap-12 lg:flex-row justify-evenly ${
          myElementIsVisible ? "appear" : ""
        }`}
      >
        <section className="space-y-8">
          <article className="space-y-4">
            <h1 className="font-bold text-3xl">Support Coordination:</h1>
            <p className=" md:text-lg">
              3/11 Vuko Place Warriewood, 2102, Australia
            </p>
          </article>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3319.606483566142!2d151.3017568447164!3d-33.69325233755563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b0d54e992303e81%3A0xcf0b431fda1b0167!2s3%2F11%20Vuko%20Pl%2C%20Warriewood%20NSW%202102%2C%20Australia!5e0!3m2!1sen!2sin!4v1717610108391!5m2!1sen!2sin"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Support Co-ordination Map"
            className="rounded-xl shadow-xl"
          />
        </section>
        <section className="space-y-8">
          <article className="space-y-4">
            <h1 className="font-bold text-3xl">Plan Management:</h1>
            <p className=" md:text-lg">87 Glen Osmaond Road Eastwood SA</p>
          </article>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3270.5024409746993!2d138.6195895158257!3d-34.94401358309101!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0cebe31231023%3A0x3e518c5fca1294f8!2s87%20Glen%20Osmond%20Rd%2C%20Eastwood%20SA%205063%2C%20Australia!5e0!3m2!1sen!2sin!4v1680967304242!5m2!1sen!2sin"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Plan Management Map"
            className="rounded-xl shadow-xl"
          />
        </section>
      </div>
      <Heading heading="Services We Offer" />
      <Services />
    </div>
  );
};
export default OurLocation;
