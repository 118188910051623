import Heading from "./Heading";
import CTA from "./CTA";
import Process from "./Process";
import { useInView } from "react-intersection-observer";

// imgs
import process_illustration from "../imgs/process_illustration.webp";
import plan_illustration from "../imgs/plan_illustration.webp";

const Plan = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });

  return (
    <section className="pt-24 text-dark">
      {/* NDIS Plan Management */}
      <article
        ref={myRef}
        className={`fade-in max-w-screen-2xl mx-auto mb-24 px-6 md:px-12 flex flex-col-reverse gap-12 lg:flex-row justify-evenly ${
          myElementIsVisible ? "appear" : ""
        }`}
      >
        <section className="lg:w-3/6 space-y-6 my-auto">
          <h1 className="font-bold text-3xl md:text-4xl">
            NDIS Plan Management
          </h1>
          <p>
            My Disability Partner Pty Ltd is an experienced NDIS plan
            management, and NDIS approved service provider providing support
            services for older people and people with disabilities. We deliver
            high-quality services to lead an independent life by boosting your
            confidence, improving your moods, and increasing social reaction. We
            are your ultimate friend for every service required to meet your
            day-to-day activities. One of the main benefits of NDIS plan
            management is that it provides individuals with flexibility and
            choice in how their funds are managed. There are several different
            options for NDIS plan management, including self-management, where
            individuals directly manage their NDIS funds; agency-managed, where
            a registered NDIS provider manages the funds on the individual's
            behalf; and plan-managed, where individuals engage a registered plan
            management provider to manage their funds. Plan-managed NDIS
            participants have the flexibility to choose their own service
            providers, and have more control over how their funds are used to
            achieve their goals.
          </p>
        </section>

        <section className="lg:w-2/6 my-auto">
          <img src={plan_illustration} alt="illustration" className="" />
        </section>
      </article>

      {/* What is NDIS Plan Management */}
      <Heading heading="What is NDIS Plan Management" />

      <article className="max-w-screen-2xl mt-12 mb-24 mx-auto px-6 md:px-12 flex flex-col md:flex-row items-center justify-evenly space-y-12 md:space-y-0">
        <section className="md:w-3/6 my-auto">
          NDIS plan management is a disability service funded through the
          National Disability Insurance Scheme (NDIS). It gives you the freedom
          and control to use your NDIS budget with the help of a plan manager
          who manages your funds, pays your bills, and watches over your budget.
          Now, plan managers help you to manage your funding in the NDIS plan.
          They guide you to increase your financial capabilities by assembling
          NDIS plan budget reports.
          <br />
          <br />
          This includes supervising the financial transactions and paperwork,
          making sure expenditures are made on reliable areas, and complying
          with the NDIS guidelines. When you choose plan management, you don't
          have to burden yourself with any paperwork because the plan managers
          will handle all of that. The NDIS plan management can help you get a
          partner to navigate the NDIS, and you will make the most out of your
          NDIS plan.
        </section>

        <section className="">
          <h2 className="text-xl md:text-xl font-bold mb-6">
            As your Plan Manager, My Disability Partner{" "}
            <br className="hidden md:block" /> will help you:
          </h2>

          <div className="space-y-8 text-left text-dark">
            <div className="text-dark/80 lg:text-lg list-none space-y-4">
              <li>
                <i className="fa-solid fa-square-check text-green-500 pr-2"></i>{" "}
                Manage your NDIS funds
              </li>
              <li>
                <i className="fa-solid fa-square-check text-green-500 pr-2"></i>{" "}
                Provide Face to Face appointments
              </li>
              <li>
                <i className="fa-solid fa-square-check text-green-500 pr-2"></i>{" "}
                Provide Over the phone consults
              </li>
              <li>
                <i className="fa-solid fa-square-check text-green-500 pr-2"></i>{" "}
                Assist in NDIS reviews if required
              </li>
              <li>
                <i className="fa-solid fa-square-check text-green-500 pr-2"></i>{" "}
                Provide a person-centered approach
              </li>
              <li>
                <i className="fa-solid fa-square-check text-green-500 pr-2"></i>{" "}
                Help you understand and best use your Plan funding
              </li>
            </div>
          </div>
        </section>
      </article>

      {/* Call To action */}
      <CTA />

      {/* How To Process  */}
      <div className="py-16 space-y-6">
        <Heading heading="How to Process" />
      </div>

      <article className="mb-24 mx-auto px-6 md:px-12 text-left ">
        <p className="md:w-3/6 mx-auto text-center text-dark/80">
          My Disability Partner Pty Ltd is an NDIS registered and experienced
          NDIS plan management company for people with disabilities. We deliver
          high-quality services to lead an independent life by tailoring your
          NDIS experience so you can get the most out of your plan and your
          life.
        </p>

        <section className="max-w-screen-xl mx-auto mt-24 lg:flex items-start justify-evenly">
          <div className="md:sticky top-32 md:w-2/6">
            <img src={process_illustration} alt="illustration" />
          </div>

          <div className="md:w-4/6">
            <Process
              count="01"
              heading="Do you have NDIS Plan Management Funding?"
              para="The NDIA will provide funding in your plan to pay for a Plan Manager. The Plan Manager  pays your providers for you, helps you keep track of funds and takes care of financial reporting for you.  This funding comes under the heading in your NDIS Plan as “Improved Life Choices”.
              If your NDIS Plan has Improved Life Choices built funding that is great news and you can work with My Disability Partner for Plan Management Services."
            />

            <Process
              count="02"
              heading="Join My Disability Partner"
              para="Once your NDIS plan has funding for Improved Life Choices, you can join our team at My Disability Partner.
              At My Disability Partner one of our highly qualified plan managers are more than happy to come see you face to face and discuss your NDIS plan and how you can achieve the best outcome for you and your NDIS Plan."
            />

            <Process
              count="03"
              heading="Notify your providers"
              para="It’s now time to inform all you service providers of the new change to your NDIS Plan and Plan Management services.
              My Disability Partner has made this easy:
              Using our contact cards, you can inform your service providers of the change in plan management services and the information they will need to send through their invoices.
              My Disability Partner is more than happy to contact all your service providers for you on your behalf and the information they will need moving forward
              Once your providers have all the information then you can sit back and allow My Disability Partner to manage the rest.
              Reimbursements can be made to you
              If you have paid for a service yourself, you can receive the funds directly back into your account. My Disability Partner will need a copy of the receipt which will show the amount, date of payment and this can be processed back to you."
            />

            <Process
              count="04"
              heading="Monitor your funding"
              para="At My Disability Partner you can download an app on your smartphone called Care view Advantage.
              The Care view Advantage App provides you with 24/7 access to:
              Current funding balances
              Let you view the invoice
              The Care view Advantage App will also:
              Send you a notification when a new invoice from your provider has been received
              Allow you to approve or decline an invoice – this will pause the payment straight away giving you time to contact us to assist on helping sort out your concerns or issues. We can also touch base with the provider on your behalf."
            />
          </div>
        </section>
      </article>

      {/* Call To action */}
      <CTA />
    </section>
  );
};

export default Plan;
