import { useInView } from "react-intersection-observer";
import Heading from "./Heading";

const Contact = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });

  return (
    <section className="text-dark">
      <article className="max-w-screen-2xl mx-auto">
        <div className="py-16 space-y-6">
          <Heading heading="Refer Participants" />
        </div>

        <section
          ref={myRef}
          className={`fade-in mb-24 md:p-6 xl:p-0 ${
            myElementIsVisible ? "appear" : ""
          }`}
        >
          <div className="">
            <form
              action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSciwlDjPyJnch8fkCS5_f-spa1sL3zxy821G1Ytp5dC7cUPbw/formResponse"
              className="max-w-screen-lg grid sm:grid-cols-2 gap-4 mx-auto px-6 pb-24"
            >
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Name*
                </label>
                <input
                  name="entry.2005620554"
                  id="name"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="">
                <label
                  htmlFor="phone"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Phone*
                </label>
                <input
                  name="entry.1702408624"
                  id="phone"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="">
                <label
                  htmlFor="email"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Email*
                </label>
                <input
                  name="entry.2078341591"
                  id="email"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="address"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Address*
                </label>
                <input
                  name="entry.649296992"
                  id="address"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="">
                <label
                  htmlFor="dob"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Date Of Birth*
                </label>
                <input
                  name="entry.2103712065"
                  id="city"
                  placeholder="DD/MM/YYYY"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="">
                <label
                  htmlFor="contactPerson"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Preferred Contact Person*
                </label>
                <input
                  name="entry.43365573"
                  id="contactPerson"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="">
                <label
                  htmlFor="contactPhone"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Preferred Contact Phone*
                </label>
                <input
                  name="entry.1875733491"
                  id="contactPhone"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="">
                <label
                  htmlFor="contactEmail"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Preferred Contact Email*
                </label>
                <input
                  name="entry.1689608336"
                  id="contactEmail"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="nameCompleating"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Name of Person Completing the Form*
                </label>
                <input
                  name="entry.1754829557"
                  id="nameCompleating"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="">
                <label
                  htmlFor="emailCompleating"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Email of Person Completing the Form*
                </label>
                <input
                  name="entry.2026975166"
                  id="emailCompleating"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="">
                <label
                  htmlFor="phoneCompleating"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Phone of Person Completing the Form*
                </label>
                <input
                  name="entry.523274722"
                  id="phoneCompleating"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="sm:col-span-2 ">
                <label
                  htmlFor="ndisNo"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  NDIS Number*
                </label>
                <input
                  name="entry.2144640089"
                  id="ndisNo"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="sm:col-span-2 flex flex-col justify-left">
                <h1
                  htmlFor=""
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  NDIS Funding*
                </h1>

                <div className="grid grid-cols-3 gap-6">
                  <div className="form-check">
                    <input
                      className="form-check-input rounded-full h-4 w-4 border border-primary transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="entry.336179890"
                      id="agency"
                      value="Agency"
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="agency"
                    >
                      Agency
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input rounded-full h-4 w-4 border border-primary transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="entry.336179890"
                      id="plan"
                      value="Plan"
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="plan"
                    >
                      Plan
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className="form-check-input rounded-full h-4 w-4 border border-primary  transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                      type="radio"
                      name="entry.336179890"
                      id="self"
                      value="Self Managed"
                    />
                    <label
                      className="form-check-label inline-block text-gray-800"
                      htmlFor="self"
                    >
                      Self Managed
                    </label>
                  </div>
                </div>
              </div>

              <div className="">
                <label
                  htmlFor="planStart"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Plan Start Date*
                </label>
                <input
                  name="entry.2038076590"
                  id="planStart"
                  placeholder="DD/MM/YYYY"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="">
                <label
                  htmlFor="planEnd"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Plan End Date*
                </label>
                <input
                  name="entry.1985435377"
                  id="planEnd"
                  placeholder="DD/MM/YYYY"
                  className="w-full bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                  required
                />
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="support"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Support Coordinator Details
                </label>
                <textarea
                  name="entry.24924519"
                  id="support"
                  className="w-full h-24 bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                ></textarea>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="disability"
                  className="inline-block text- text-sm sm:text-base mb-2 font-bold tracking-widest"
                >
                  Disability / Diagnosis
                </label>
                <textarea
                  name="entry.1576395522"
                  id="disability"
                  className="w-full h-24 bg-transparent focus:bg-primary/10 border border-primary focus:border-primary/50 focus:ring ring-primary/50 rounded outline-none transition duration-100 px-3 py-2"
                ></textarea>
              </div>

              <div className="sm:col-span-2 flex justify-between items-center">
                <button
                  type="submit"
                  className="inline-block bg-secondary hover:bg-secondary/70 focus-visible:ring ring-indigo-300 text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3"
                >
                  Submit Request
                </button>

                <span className="text-gray-500 text-sm">*Required</span>
              </div>
            </form>
          </div>
        </section>
      </article>
    </section>
  );
};

export default Contact;
