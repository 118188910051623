import Heading from "./Heading";
import Button from "./Button";

const Funding = () => {
  return (
    <section className="pt-24 text-dark">
      <Heading heading="About NDIS Funding" />

      <article className="max-w-7xl mx-auto px-6 pt-12 pb-24 text-lg space-y-12">
        <div className="space-y-4">
          <header className="font-semibold text-xl">
            {" "}
            It’s common for people to be overwhelmed by their NDIS plans and
            wonder what all the categories mean. Don’t worry, we can explain.
          </header>

          <p>
            There are three types of support budgets that may appear in your
            NDIS plan: core, capacity building and capital supports. Your NDIS
            planner will tailor your NDIS plan with support budget categories
            and funding connected to achieving your goals and supporting you in
            your day-to-day life.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-semibold text-xl">CORE SUPPORTS</header>

          <p>
            The core supports budget is funding to support all your assistance
            with day-to-day tasks that relate to your plan and life goals. Your
            core funding is where all your carer, support worker, cleaning and
            other regular expenses will be paid from. The core budget is often
            flexible across the four support budget categories meaning you can
            choose to spend your funding interchangeably between them. For
            example, you may want to spend more of your total core funding on
            continence products and less on your transport expenses. The four
            budget support categories under core support are:
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>01: Assistance with Daily Life</em>
          </header>

          <p>
            Name on NDIS portal – “Daily Activities”. This budget will cover all
            your expenses related to assisting you to undertake daily tasks.
            This could include:
            <li>
              personal care, cleaning, gardening or meal preparation (with
              approval from the NDIS)
            </li>
            <li>
              assistance with laundry – linen service (with approval from the
              NDIS)
            </li>
            <li>access to alternative living arrangements</li>
            <li>
              short and medium term accommodation and assistance (including the
              provision of respite care)
            </li>
            <li>Supported Independent Living (SIL) options</li>
            <li>
              assistance with daily life tasks provided in residential aged care
              facility
            </li>
            <li>
              nursing care to assist with your disability related health needs
            </li>
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>02: Transport</em>
          </header>

          <p>
            Funding to cover transport expenses related to the impact of your
            disability, such as:
            <li>taxi expenses if you are unable to access public transport</li>
            <li>
              specialised transport services to attend school, educational
              facility, employment, or the community
            </li>
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>03: Consumables</em>
          </header>

          <p>
            Funding to pay for everyday items and low risk/low cost products,
            like:
            <li>
              products related to your disability like nutritional supplements
              or continence products
            </li>
            <li>interpreting and translating support</li>
            <li>Auslan or signed English training</li>
            <li>
              low-cost assistive technology for personal care and safety, such
              as modifed cutlery and hand rails to improve your independence
              and/or mobility – you may also use these funds to do minor repairs
              to aids and equipment (assistive technology), for example
              wheelchair tyre puncture repair
            </li>
            <li>low cost assistive technology for prosthetics and orthotics</li>
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>
              04: Assistance with Social, Economic and Community participation
            </em>
          </header>

          <p>
            Name on NDIS portal – “Social, community and civic participation”.
            Covers cost to access and participate in the community, such as:
            <li>
              assistance with engaging in a community, social or recreational
              activity
            </li>
            <li>
              support can be provided in a range of environments such as in a
              community setting or centre
            </li>
            <li>
              one-to-one support to assist you with attending a social outing,
              holiday, camp, class or specific interest/hobby i.e. art, music,
              drama class
            </li>
            <li>
              participate in sport and exercise including joining a sporting
              club
            </li>
            <li>assistance to attend a community event such as the movies</li>
            <li>
              activity based transport including cost per km, parking, road
              tolls and public transport fares
            </li>
            <li>support to maintain your current employment</li>
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">CAPITAL SUPPORTS</header>

          <p>
            The capital supports budget pays for products to reduce the impact
            of your disability. These budgets are not very flexible and usually
            cover specific items which were discussed in your planning meeting
            and have been approved in your plan by the NDIA.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>05: Assistive Technology</em>
          </header>

          <p>
            Specialised technology or equipment, like a smart device,
            wheelchair, or app.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>06: Home Modifications</em>
          </header>

          <p>
            Funding for modifications to be made to your home to make it easier
            for to live your everyday life where your disability creates
            barriers. This may include modifications to your kitchen or bathroom
            equipment.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">
            CAPACITY BUILDING SUPPORTS
          </header>

          <p>
            The capacity building supports budget is funding specifically
            allocated to assist you to build your skills and achieve your goals.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>07: Support Coordination</em>
          </header>

          <p>
            A support coordinator can be claimed from this budget. Support
            coordinators help you put your plan in action and organise your
            supports. They can assist you to build connections with the
            community and broader systems of support; develop and design support
            options to help you work towards your goals; and work with you to
            prepare for review and report on what you’ve achieved.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>08: Improved Living Arrangements</em>
          </header>

          <p>
            Name on NDIS portal – “CB Home Living”. This funding is for help for
            you to find suitable accommodation to live more independently, and
            could cover someone to assist you with inspecting properties or
            negotiating your contract. Supports may including assistance with
            applying for a rental property, meeting tenancy obligations and
            ensuring the home is appropriate for your needs.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>09: Increased Social and Community Participation</em>
          </header>

          <p>
            Name on NDIS portal – “CB Social Community and Civic Participation”.
            This is to pay for support to help you build your skills in
            accessing the community. This may include funding a mentor or
            therapist to assist with learning skills for independence in the
            community.
            <br />
            <br />
            This category of funding can be used to pay for tuition fees, art
            classes, sports coaching and similar activities that build skills
            and independence. You can use this category to pay for camps,
            classes and vacation activities that have capacity-building
            components. Activity based transport is also included.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>10: Finding and Keeping a Job</em>
          </header>

          <p>
            Name on NDIS portal – “CB Employment”. Funding to support you to
            find a job and have assistance in your work. This budget can pay for
            a support worker to assist you at your job or helping you to find a
            job that’s right for you, including:
            <br />
            <br />
            on the job training to assist you manage the demands of the job
            <br />
            <br />
            other options may include life and work coaches, private recruitment
            specialists, career counsellors and employment mentors
            <br />
            <br />
            This category of funding can be used to pay for tuition fees, art
            classes, sports coaching and similar activities that build skills
            and independence. You can use this category to pay for camps,
            classes and vacation activities that have capacity-building
            components. Activity based transport is also included.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>11: Improved Relationships</em>
          </header>

          <p>
            Name on NDIS portal – “CB Relationships”. Supports to help build
            your skills to connect and build relationships in your community.
            This could include funding to pay for someone to help you build your
            social skills or behavioural therapy services.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>12: Improved Health and Wellbeing</em>
          </header>

          <p>
            Name on NDIS portal – “CB Health and Wellbeing”. Services that
            improve your health and wellbeing, like a personal trainer, exercise
            physiologist or dietitian to improve your skills in maintain your
            own health and wellbeing.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>13: Improved Learning</em>
          </header>

          <p>
            Name on NDIS portal – “CB Lifelong Learning”. Access to services
            that will help you to transition from school to further education.
            This budget could fund a support worker to help you to go to
            university or TAFE.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>14: Improved Life Choices</em>
          </header>

          <p>
            Name on NDIS portal – “CB Choice and Control”. This budget pays for
            your plan manager.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-medium text-xl bg-secondary text-light">
            <em>15: Improved Daily Living</em>
          </header>

          <p>
            Name on NDIS portal – “CB Daily Activity”. Funding to cover your
            therapies and other programs to build your capacity to reduce the
            impact of your disability. This could be a physiotherapist, speech
            pathology, OT or any other therapy that assists you to become more
            independent with your daily living tasks.
          </p>
        </div>

        <div className="flex flex-col gap-4 md:block md:space-x-4">
          <Button href="mailto:invoices@mdpartner.com.au" text="Mail Us" />
          <Button href="tel:+611300110251" text="Call Us" />
        </div>
      </article>
    </section>
  );
};

export default Funding;
