import Services from "./Services";
import ChooseUs from "./ChooseUs";
import Heading from "./Heading";
import Timeline from "./Timeline";
import CTA from "./CTA";
import { useInView } from "react-intersection-observer";

// imgs
import about_illustration from "../imgs/about_illustration.webp";

const About = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });

  return (
    <section className="pt-12">
      {/* About MDP */}
      <div className="py-10">
        <Heading heading="About MDP" />
      </div>

      <article
        ref={myRef}
        className={`fade-in max-w-screen-2xl mx-auto px-6 md:px-12 flex flex-col lg:flex-row justify-center ${
          myElementIsVisible ? "appear" : ""
        }`}
      >
        <section className="lg:w-3/6 my-auto">
          <img
            src={about_illustration}
            alt="illustration"
            className="lg:w-4/6 mx-auto"
          />
        </section>

        <section className="lg:w-3/6 spacey-12">
          My Disability Partner helps you monitor your NDIS funds and provide
          you with financial reporting. A qualified plan manager will support
          you to understand the NDIS plan and implement the policies that are
          included in your plan. In simple words, we provide support to the
          people with disabilities. We, as Plan Managers, monitor your NDIS
          plans, work out the best strategies to get maximum benefits from these
          plans, and finally keep an eye on the expenses so that it coincides
          with your budget. It is our responsibility to make sure you are not
          held back because of any disability. MDP wants to provide you with
          expertise so that you can live your lives independently and
          confidently.
          <br />
          <br />
          You get the same flexibility as self-management, without the
          difficulties of administration. A plan manager receives and pays your
          invoices, claims from the NDIS portal, provides regular budget updates
          and takes care of all administrative procedures on your behalf. We are
          also able to make provider recommendations, help with goal setting and
          support coordination between providers.We make sure that our services
          and policies are in accordance with the NDIS guidelines. So, MDP is
          here to help you make your NDIS planning easier, more secure and
          organized.
          <div className="lg:w-5/6 mx-auto pt-12 space-y-8 text-left text-dark">
            <h2 className="font-bold text-xl">My Disability Partner can:</h2>
            <div className="text-dark/80 lg:text-lg list-none space-y-6 md:space-y-0">
              <li>
                <i className="fa-solid fa-square-check text-green-500 pr-2"></i>{" "}
                Manage your NDIS funds
              </li>
              <li>
                <i className="fa-solid fa-square-check text-green-500 pr-2"></i>{" "}
                Provide Face to Face appointments
              </li>
              <li>
                <i className="fa-solid fa-square-check text-green-500 pr-2"></i>{" "}
                Provide Over the phone consults
              </li>
              <li>
                <i className="fa-solid fa-square-check text-green-500 pr-2"></i>{" "}
                Assist in NDIS reviews if required
              </li>
              <li>
                <i className="fa-solid fa-square-check text-green-500 pr-2"></i>{" "}
                Provide a person-centered approach
              </li>
              <li>
                <i className="fa-solid fa-square-check text-green-500 pr-2"></i>{" "}
                Help you understand and best use your Plan funding
              </li>
            </div>
          </div>
        </section>
      </article>

      {/* Why choose us */}
      <div className="py-16 space-y-6">
        <Heading heading="Why Choose Us" />
      </div>

      <article className="max-w-screen-xl mx-auto mb-24 px-6 md:px-12 bg-contain grid md:grid-cols-2 lg:grid-cols-3 gap-12">
        <ChooseUs
          heading="Easy Communication"
          text="MDP can arrange translators to exchange information and communication in your preferred language."
        />
        <ChooseUs
          heading="Support"
          text="We help you create a Care Plan and regularly review the plan that is tailored for your specific needs"
        />
        <ChooseUs
          heading="Diversity"
          text="Join us to enjoy a diversity of experiences. Our care and support section is the best in many situations."
        />
        <ChooseUs
          heading="Quality Services"
          text="Improve your quality of life through our client’s high-quality services. Enjoy the quality of life you deserve."
        />
        <ChooseUs
          heading="Dedication"
          text="We are committed to assisting you to be the controller of your care needs. Plan your requirements with us."
        />
        <ChooseUs
          heading="Respect"
          text="We note down even little things that matter to you. We will work together to achieve your every desired goal."
        />
        <ChooseUs
          heading="Your Choice"
          text="Just let us know your time, type, and location, and we will handle everything. We will provide offers at your preferred time and location."
        />
        <ChooseUs
          heading="Building Relationships"
          text="We guarantee a comfortable working relationship building a healthy environment. Our services and care are transparent."
        />
        <ChooseUs
          heading="Trust"
          text="Our highly qualified team respects your privacy. We care about your personal space and will never interfere with your privacy."
        />
      </article>

      {/* Call to action */}
      <CTA />

      {/* What makes us stand out*/}
      <div className="mt-24 py-12 px-2 space-y-6">
        <Heading heading="What Makes Us Stand Out" />
      </div>

      <article className="max-w-screen-2xl mx-auto bg-timeline-bg2 bg-contain bg-no-repeat bg-center">
        <div className="container mx-auto">
          <div className="timeline">
            <ul>
              <Timeline
                img="https://images.pexels.com/photos/4467860/pexels-photo-4467860.jpeg?auto=compress&cs=tinysrgb&w=1600"
                heading="Flexible"
                text="We are just a phone call away. We are available 24/7 and aim to provide services."
              />
              <Timeline
                img="https://images.pexels.com/photos/3810792/pexels-photo-3810792.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                heading="Experienced Team"
                text="We have a team of highly
                qualified workers to support
                you."
              />
              <Timeline
                img="https://images.pexels.com/photos/13706807/pexels-photo-13706807.jpeg?auto=compress&cs=tinysrgb&w=1600"
                heading="Affordable"
                text="My Disability Partner is cheaper than most of our competitors. We offer quality services."
              />
              <Timeline
                img="https://images.pexels.com/photos/4631064/pexels-photo-4631064.jpeg?auto=compress&cs=tinysrgb&w=1600"
                heading="Choice & Control"
                text="Having your plan, plan managed gives you more choice and control over the providers you choose."
              />
              <Timeline
                img="https://images.pexels.com/photos/8259503/pexels-photo-8259503.jpeg?auto=compress&cs=tinysrgb&w=1600"
                heading="We Come To You"
                text="Do you want to speak to someone face to face? Do you need the reassurance of a physical person? At My Disability Partner, our plan managers will travel to you."
              />
              <Timeline
                img="https://images.pexels.com/photos/3913027/pexels-photo-3913027.jpeg?auto=compress&cs=tinysrgb&w=1600"
                heading="Person-Centred Approach"
                text="At MDP we support the individual at the (centre of the service) to be involved in making decisions about their life. We take into account each person's age, gender, culture, heritage, language, beliefs and identity by providing a flexible service and supports to suit the person's wishes and priorities."
              />
            </ul>
          </div>
        </div>
      </article>

      {/* What We Offer */}
      <div className="py-24 space-y-24">
        <Heading heading="Services We Offer" />
        <Services />
      </div>
    </section>
  );
};

export default About;
