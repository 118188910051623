import Heading from "./Heading";
import Button from "./Button";

const TermsAndConditions = () => {
  return (
    <section className="px-8 pt-12">
      <div className="max-w-screen-2xl mx-auto py-12 space-y-6">
        <Heading heading="Terms & Conditions" />
      </div>

      <article className="max-w-7xl mx-auto px-6 pt-12 pb-24 text-lg space-y-12">
        <div className="space-y-4">
          <header className="font-bold text-2xl">
            Application, variation and termination of these Terms and Conditions
          </header>

          <p>
            These Terms and Conditions govern the website operated and provided
            by My Disability Partner Pty Ltd. Use of My Disability Partner Pty
            Ltd’s website by you constitutes acceptance of these Terms and
            Conditions My Disability Partner Pty Ltd may at any time, in its
            sole discretion, amend, vary or modify these Terms and Conditions
            without notice. Modifications to these Terms and Conditions will be
            effective immediately and any subsequent use by you of this website
            will constitute your acceptance of the modifications and the
            then-current terms and conditions of use of this website. My
            Disability Partner Pty Ltd may, without notice, terminate or suspend
            this website or any part of the website at any time.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">Privacy</header>

          <p>
            Any personal information received by My Disability Partner Pty Ltd
            will be collected and disclosed in accordance with My Disability
            Partner Pty Ltd’s Privacy Policy (as updated from time to time and
            available her).
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">
            Disclaimer and Liability
          </header>

          <p>
            While My Disability Partner Pty Ltd provides the website and its
            contents in good faith, My Disability Partner Pty Ltd does not
            accept liability or responsibility for any content or material on
            this website, including for the accuracy, completeness,
            authenticity, reliability or timeliness of the content; My
            Disability Partner Pty Ltd does not represent or warrant that this
            website, any files obtained from or through this website are free
            from harmful code, computer viruses or other defects; To the extent
            permitted by law, My Disability Partner Pty Ltd expressly excludes
            all conditions and warranties (including warranties as to
            merchantability, non-infringement of intellectual property or any
            other rights or fitness for a particular purpos, guarantees, rights
            and remedies, liabilities and other terms that may be implied by
            custom, statute or common law in relation to any information or
            materials on this website or any websites linked to this website;
            and My Disability Partner Pty Ltd is not liable for any
            consequential losses (including any loss of revenue or profits, loss
            of anticipated savings, loss of data, loss of value of equipment,
            any penalties or fines imposed by a regulator and any loss that is
            indirect loss) that you suffer or for any costs, damages, expenses,
            loss or charges that you incur as a result of your use any
            information or materials on this website or any websites linked to
            this website.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">Intellectual Property</header>

          <p>
            Intellectual property rights (including any copyright, trademark
            rights) in the content on this website, vests in My Disability
            Partner Pty Ltd, subject to any third party rights. My Disability
            Partner Pty Ltd, the My Disability Partner Pty Ltd’s logo, the logo,
            and the icon are trademarks of My Disability Partner Pty Ltd,
            registered in Australia. Nothing contained on this website is to be
            construed as granting a licence to use any content on this website.
            You may not use, reproduce or modify any content displayed on this
            website including My Disability Partner Pty Ltd’s logo or
            trademark/s without My Disability Partner Pty Ltd’s written consent.
            You may not pass off content from this website as your own. This
            includes the practice of ‘Framing’ this website within another
            website as well as copying and re-using parts of the website. No
            part should be reproduced, copied, stored, distributed or
            transmitted in any form, or by any means, including photocopying,
            scanning, or other mechanical or electronic methods without the
            prior written permission of My Disability Partner Pty Ltd. To seek
            permission, please contact
            <a
              href="mailto:info@mdpartner.com.au"
              className="text-primary underline underline-offset-4"
            >
              {" "}
              info@mdpartner.com.au.
            </a>
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">
            Linking to other website
          </header>

          <p>
            This website may contain links to other websites. My Disability
            Partner Pty Ltd does not warrant approval of or accept any liability
            or responsibility for any such websites. Access to these websites is
            at your own risk.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">
            Guidelines for linking to this website
          </header>

          <p>
            You are welcome to put links on your website pointing to the My
            Disability Partner Pty Ltd’s website. In such case we request that
            you adopt the following guidelines for linking: Links to My
            Disability Partner Pty Ltd’s site should usually be to the home
            page, i.e.{" "}
            <a
              href="https://www.mdpartner.com.au."
              className="text-primary underline underline-offset-4"
            >
              {" "}
              My Disability Partner
            </a>{" "}
            Linking to other parts of the site poses some risk, as the page or
            service to which you link may move or become unavailable. The
            correct name for the website is My Disability Partner Pty Ltd. My
            Disability Partner Pty Ltd’s logo and its variants are registered
            trademarks and subject to copyright. They may only be used with
            written permission from My Disability Partner Pty Ltd.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">
            Cookies and other features of our website
          </header>

          <p>
            This website uses cookies to identify visitors to this site, gather
            statistical information, store user preferences, activity, and
            session information. My Disability Partner Pty Ltd uses cookies to
            improve security, enhance website functionality and improve its
            services. By using this website, you are consenting to My Disability
            Partner Pty Ltd’s use of cookies on your device. You can disable
            cookies in your browser settings. However, adjusting these settings
            may affect the functionality of this website.
            <br />
            <br />
            Information stored in cookies can include:
            <div>
              <li>The identity of the page or services you are requesting;</li>
              <li>The type of browser or operating system in use;</li>
              <li>Time and date of access;</li>
              <li>
                The internet address of the website from which you navigated
                from to reach our website.{" "}
              </li>
            </div>
            <br />
            Cookies do not store personal information such as:
            <div>
              <li>your name</li>
              <li>email addresses</li>
              <li>phone numbers</li>
            </div>
            <br />
            My Disability Partner Pty Ltd uses web analytics and session
            recording technology provided by third-party service providers such
            as Google Analytics and Hotjar. These services use cookies to assist
            us to understand how visitors access and utilise our site. For more
            information see My Disability Partner Pty Ltd’s Privacy Policy (as
            updated from time to time and available her. My Disability Partner
            Pty Ltd also uses JavaScript, which is a programming language that
            helps our website work. In order to use our website, we recommend
            that you have JavaScript enabled in your browser settings. To enable
            JavaScript in your browser, follow the instructions for your browser
            version here.
          </p>
        </div>

        <div className="space-y-4">
          <header className="font-bold text-2xl">Governing Law</header>

          <p>
            The laws of the State of New South Wales, Australia governs these
            Terms and Conditions and any agreement formed under them. You and My
            Disability Partner Pty Ltd irrevocably and unconditionally submit to
            the non-exclusive jurisdiction of the Courts of the State of New
            South Wales, Australia
          </p>
        </div>

        <div className="flex flex-col gap-4 md:block md:space-x-4">
          <Button href="mailto:invoices@mdpartner.com.au" text="Mail Us" />
          <Button href="tel:+611300110251" text="Call Us" />
        </div>
      </article>
    </section>
  );
};

export default TermsAndConditions;
