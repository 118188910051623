import { useInView } from "react-intersection-observer";

import Button from "./Button";
import Heading from "./Heading";
import Services from "./Services";
import Timeline from "./Timeline";
import CTA from "./CTA";
import Carousel from "./Carousel";

// Images
import about_img from "../imgs/about_img.webp";
import landing_poster from "../imgs/poster.webp";
import vector1 from "../imgs/vector1.svg";
import vector2 from "../imgs/vector2.svg";
import vector3 from "../imgs/vector3.svg";
import vector4 from "../imgs/vector4.svg";

// Videos
import heroVid from "../vids/heroVid.mp4";
import FAQ from "./FAQ";

const Home = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });

  return (
    <section className="sm:20 text-dark bg-contain bg-no-repeat bg-top overflow-x-hidden">
      {/* <!-- NEW LANDING PAGE  --> */}
      <section className="showcase">
        <video
          id="video"
          src={heroVid}
          muted={true}
          loop={true}
          playsInline={true}
          autoPlay={true}
          poster={landing_poster}
        ></video>

        <div className="overlay bg-zinc-900/50"></div>

        <article className="max-w-screen-2xl mx-auto relative px-6 md:px-12 2xl:px-6 flex flex-row items-center">
          <div className="md:w-5/6 space-y-2 md:space-y-0">
            <h2
              id="reveal"
              className="font-satoshi font-bold text-3xl sm:text-5xl md:text-3xl lg:text-4xl xl:text-7xl"
            >
              Empower Your NDIS Journey with Our Best Management Plans
            </h2>

            <p
              id="reveal_para"
              className="font-extralight text-base lg:text-2xl pt-6 lg:leading-tight"
            >
              You possess unique abilities. You are not disabled, you are
              <br className="hidden lg:block" /> differently-abled.
            </p>

            <aside
              id="reveal_btn"
              className="hidde mdblock pt-12 md:pt-16 space-y-2"
            >
              <Button href="/plan" text="Plan Management" />
              <p className="text-light/80 ">
                Need any help?{" "}
                <a href="/contact" className="font-semibold text-light">
                  Contact Us
                </a>
              </p>
            </aside>
          </div>
        </article>
      </section>

      {/* <!-- BLANK SPACE --> */}
      <div className="h-screen mt32"></div>

      {/* Services We Offer */}
      <div className="py-24 space-y-24">
        <Heading heading="Services We Offer" />
        <Services />
      </div>

      {/* Key Features */}
      <article className="bg-dark text-light py-20 md:px-12">
        <section className="max-w-screen-xl mx-auto flex flex-col md:flex-row justify-between items-center gap-24">
          <div className="flex flex-col gap-6 text-center">
            <img
              src={vector1}
              alt="icon"
              className="w-3/12 md:w-2/6 lg:w-3/12 mx-auto"
            />
            <h2 className="font-semibold text-sm md:text-sm lg:text-lg">
              NDIS Planning & <br /> Management
            </h2>
          </div>

          <div className="flex flex-col gap-6 text-center">
            <img
              src={vector2}
              alt="icon"
              className="w-3/12 md:w-2/6 lg:w-2/12 mx-auto"
            />
            <h2 className="font-semibold text-sm md:text-sm lg:text-lg">
              Daily Living & <br /> Life Skills Development
            </h2>
          </div>

          <div className="flex flex-col gap-6 text-center">
            <img
              src={vector3}
              alt="icon"
              className="w-3/12 md:w-2/6 lg:w-3/12 mx-auto"
            />
            <h2 className="font-semibold text-sm md:text-sm lg:text-lg">
              Travel & Transport <br /> Assistance
            </h2>
          </div>

          <div className="flex flex-col gap-6 text-center">
            <img
              src={vector4}
              alt="icon"
              className="w-3/12 md:w-2/6 lg:w-3/12 mx-auto"
            />
            <h2 className="font-semibold text-sm md:text-sm lg:text-lg">
              Daily High-Intensity <br /> Personal Activities{" "}
            </h2>
          </div>
        </section>
      </article>

      {/* Who We Are */}
      <div
        ref={myRef}
        className={`fade-in max-w-screen-2xl mx-auto mt-24 py-12 space-y-6 ${
          myElementIsVisible ? "appear" : ""
        }`}
      >
        <Heading heading="Who We Are" />

        <img
          src={about_img}
          alt="Od women getting help"
          className="px-4 lg:max-w-screen-xl mx-auto"
        />
      </div>

      <article className="max-w-screen-xl mb-24 mx-auto px-6 md:px-12">
        <p className="mx-auto text-dark text-sm lg:text-base">
          My Disability Partner is a dedicated team of experienced Plan Managers
          who offer support in managing your NDIS plan, which aims to help
          people with disabilities achieve their goals and improve their quality
          of life. We understand that managing your NDIS plan can be a
          challenging task, which is why we are here to assist you in maximizing
          the benefits of your plan, and making your life easier.
          <br />
          <br />
          Our team follows a person-centered approach, which means that we focus
          on you and your unique needs, preferences, and goals. We work with you
          to understand your requirements, and we keep you informed about your
          funding and budget, ensuring that you are always up-to-date. Our aim
          is to build a trustworthy bond with every client through face-to-face
          interactions and provide the best possible service that meets your
          specific needs.
          <br />
          <br />
          We are committed to lessening the stress out of your NDIS journey, and
          ensuring that you live your life to the fullest. Our team is
          passionate about uplifting people's lives with disabilities by
          providing quality and trustworthy services that are tailored to your
          needs. With My Disability Partner, you can experience more
          independence and better control of your support. Our services are
          designed to empower you, and we are dedicated to supporting you in
          every step of your NDIS journey.
        </p>
        <aside className="pt-12 space-y-2 text-center">
          <Button href="/about" text="Know More" />
        </aside>
      </article>

      {/* What we Guarantee */}
      <Heading heading="What We Guarantee" />

      <article className="max-w-screen-xl mb-24 mx-auto bg-timeline-bg bg-contain bg-no-repeat bg-center">
        <div className="container mx-auto px-6 md:px-12">
          <div className="timeline">
            <ul>
              <Timeline
                img="https://images.pexels.com/photos/4990544/pexels-photo-4990544.jpeg?auto=compress&cs=tinysrgb&w=1600"
                heading="Leading"
                text="We are one of the leading NDIS service providers and comply with Government requirements."
              />
              <Timeline
                img="https://images.pexels.com/photos/3894377/pexels-photo-3894377.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                heading="Qualified"
                text="We have trained and highly qualified health care nurses and support workers."
              />
              <Timeline
                img="https://images.pexels.com/photos/6773517/pexels-photo-6773517.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                heading="Trustworthy"
                text="We work closely with you to develop trustworthy relationships to encourage growth and resilience."
              />
              <Timeline
                img="https://images.pexels.com/photos/7172814/pexels-photo-7172814.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                heading="Growth"
                text="We are dedicated to ensuring that every people with a disability continue to grow physically, socially, and psychologically."
              />
            </ul>
          </div>
        </div>
      </article>

      {/* Call to action */}
      <CTA />

      {/* What they say */}
      <Heading heading="What They Say" />

      {/* Testimonial Carousel */}
      <Carousel />

      {/* Faq */}
      <Heading heading="FAQ" />
      <FAQ />
    </section>
  );
};

export default Home;
