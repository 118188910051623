import { Link } from "react-router-dom";
const Button = ({ href, text }) => {
  return (
    <Link to={href}>
      <div className="font-semibold w-5/6 md:w-4/12 py-4 bg-primary hover:bg-secondary text-center text-sm lg:text-base tracking-wide text-light hover:text-neutral transition-all rounded-full inline-block">
        {text}
      </div>
    </Link>
  );
};

export default Button;
