import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// imgs
import logo from "../imgs/logo.svg";
import registered_ndis_provider from "../imgs/registered_ndis_provider.webp";

const Footer = () => {
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newYear = new Date().getFullYear();
      if (newYear !== year) {
        setYear(newYear);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [year]);

  return (
    <section className="bg-dark pt-20 pb-6 px-6 text-light text-sm font-light">
      <article className="max-w-screen-2xl mx-auto text-left grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-8">
        <div className="space-y-6">
          <img src={logo} alt="mdp logo" className="w-4/6 md:w-5/6" />
          <p className="text-sm">
            My Disability Partner is an experienced approved NDIS plan
            management provider providing services for people with disabilities.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="font-bold text-lg text-primary mb-2">Services</h2>
          <Link
            to="/plan"
            className="cursor-pointer text-neutral hover:text-light/70 hover:underline underline-offset-4 transition-colors"
          >
            NDIS Plan Management
          </Link>
          <Link
            to="/funding"
            className="cursor-pointer text-neutral hover:text-light/70 hover:underline underline-offset-4 transition-colors"
          >
            NDIS Funding
          </Link>
          <Link
            to="/support"
            className="cursor-pointer text-neutral hover:text-light/70 hover:underline underline-offset-4 transition-colors"
          >
            Support Co-ordination
          </Link>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="font-bold text-lg text-primary mb-2">Others</h2>
          <Link
            to="/privacy-policy"
            className="cursor-pointer text-neutral hover:text-light/70 hover:underline underline-offset-4 transition-colors"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms-condition"
            className="cursor-pointer text-neutral hover:text-light/70 hover:underline underline-offset-4 transition-colors"
          >
            Terms & Condition
          </Link>
          <Link
            to="/feedback"
            className="cursor-pointer text-neutral hover:text-light/70 hover:underline underline-offset-4 transition-colors"
          >
            Give us a Feedback 💌
          </Link>
        </div>

        <div className="flex flex-col gap-2">
          <h2 className="font-bold text-lg text-primary mb-2">Details</h2>
          <div>
            <span className="font-semibold">Address:</span> PO BOX 547 Noarlunga
            Centre South Australia
            <br />
            <br />
            <span className="font-semibold">
              Support Coordination Head Office:
            </span>{" "}
            3/11 Vuko Place Warriewood, 2102, Australia
            <br />
            <br />
            <p className="pb-4">
              Support Coordination:{" "}
              <a
                href="mailto:caroline@mdpartner.com.au"
                className="font-medium underline underline-offset-4 hover:text-secondary"
              >
                caroline@mdpartner.com.au
              </a>
            </p>
            <p>
              Email us:{" "}
              <a
                href="mailto:info@mdpartner.com.au"
                className="font-medium underline underline-offset-4 hover:text-secondary"
              >
                info@mdpartner.com.au
              </a>
            </p>
          </div>
        </div>

        <div className="text-center md:text-left">
          <img
            src={registered_ndis_provider}
            alt="registered ndis provider stamp"
            className="w-4/6 md:w-full mx-auto md:mx-0 mb-4"
          />
          <div className="">
            <a
              href="mailto:info@mdpartner.com.au"
              className="font-semibold w-full py-4 bg-primary hover:bg-secondary text-center text-sm lg:text-base tracking-wide text-light hover:text-neutral transition-all rounded-full inline-block"
            >
              Mail Us
            </a>
          </div>
        </div>
      </article>

      <hr className="w-5/6 mx-auto border-light my-8" />

      <article className="space-y-4 md:space-y-0 text-center md:flex justify-between max-w-screen-2xl mx-auto">
        <h2>CopyRight © {year} My Disability Partner</h2>
        <h2>
          Site by{" "}
          <a
            href="https://varunbhabhra.com/"
            target="__blank"
            className="hover:text-light/70 font-medium underline underline-offset-4"
          >
            Varun Bhabhra <i className="fa-solid fa-link"></i>
          </a>
        </h2>
      </article>
    </section>
  );
};

export default Footer;
