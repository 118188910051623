import { useInView } from "react-intersection-observer";

const backgrounds = [
  "https://images.unsplash.com/photo-1626668011660-051379e9b211?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  "https://images.unsplash.com/photo-1587556930799-8dca6fad6d41?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  "https://images.unsplash.com/photo-1513159446162-54eb8bdaa79b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  "https://images.unsplash.com/photo-1522016480855-67a9f8416d98?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  "https://images.unsplash.com/photo-1526225294770-079fcbe68745?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
];

const headline = [
  "Want a hassle-free NDIS plan management experience?",
  "Need Help to Apply for NDIS?",
  "Unsatisfied with your current plan manager?",
  "Let us help you navigate the NDIS process.",
  "We are trusted NDIS plan management professionals.",
];

const CTA = ({ text }) => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });
  const number = Math.floor(Math.random() * 5);

  return (
    <article
      ref={myRef}
      className={`fade-in max-w-screen-xl mx-auto bg-dark w-full mb-12 bg-cover bg-center relative text-center xl:rounded-xl ${
        myElementIsVisible ? "appear" : ""
      }`}
    >
      <img
        src={backgrounds[number]}
        alt="Old Men Playing Chess"
        className="w-full h-full object-cover absolute mix-blend-overlay rounded-xl"
      />
      <div className="py-24 px-4 md:p-24 text-light relative space-y-6 lg:w-5/6 mx-auto">
        <h2 className="font-bold text-3xl md:text-5xl">{headline[number]}</h2>
        <p className="font-extralight text-2xl lg:text-3xl pb-10">Try Us!</p>
        <a href="/contact" className="">
          <div className="font-semibold w-5/6 md:w-4/12 py-4 bg-primary hover:bg-secondary text-center text-sm lg:text-sm tracking-wide text-light hover:text-neutral transition-all rounded-full inline-block">
            Contact Us
          </div>
        </a>
      </div>
    </article>
  );
};

export default CTA;
