const Heading = ({ heading }) => {
  return (
    <header className="space-y-6">
      <h2 className="font-semibold text-center text-3xl md:text-4xl lg:text-4xl text-dark">
        {heading}
      </h2>
      <hr className="w-2/6 md:w-12 border border-dark mx-auto" />
    </header>
  );
};

export default Heading;
