import Testimonial from "./Testimonials";

const Carousel = () => {
  return (
    <div
      id="controls-carousel"
      className="relative max-w-screen-lg my-12 mx-auto"
      data-carousel="slide"
    >
      {/* <!-- Carousel wrapper --> */}

      <div className="relative h-[24rem] lg:h-96 overflow-hidden rounded-lg text-sm md:text-base">
        {/* <!-- Item 1 --> */}
        <div
          className="duration-700 ease-in-out absolute inset-0 transition-all transform -translate-x-full z-10"
          data-carousel-item=""
        >
          <Testimonial
            text="Thank You.
                Any moment now bike gets too start first time over 14 years apart in a barn.
                Everything ready and sorted.
                Just a start up big moment person like me.
                It is so very appreciated.
                I am having a great weekend and hope you have a fantastic weekend doing exactly what you like and work for.
                Everyone you have sent so fantastic and shows a great depth of understanding I find extremely rare today.
                It's such a relief and pleasure knowing I'm in safe hands when never had hand and you make it feel good for an independent man.
                Stubborn man. Tries far too much too do everything himself so others need not ever need concern themselves.
                Goes unnoticed usually.
                You know that and far out what a huge change you have made.
                Please have a compliment and know from someone who knows kindness and how rare it gets noticed.
                Have top time"
            img="https://randomuser.me/api/portraits/men/18.jpg"
            name="Paul Ludeman"
          />
        </div>
        {/* <!-- Item 2 --> */}
        <div
          className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-10"
          data-carousel-item="active"
        >
          <Testimonial
            text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text."
            img="https://randomuser.me/api/portraits/men/19.jpg"
            name="Dane Doe"
          />
        </div>

        {/* <!-- Item 3 --> */}
        <div
          className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-full z-10"
          data-carousel-item=""
        >
          <Testimonial
            text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text."
            img="https://randomuser.me/api/portraits/men/20.jpg"
            name="Joe Doe"
          />
        </div>

        {/* <!-- Item 4 --> */}
        <div
          className="duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-full z-10"
          data-carousel-item=""
        >
          <Testimonial
            text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text."
            img="https://randomuser.me/api/portraits/men/28.jpg"
            name="Jay Doe"
          />
        </div>
      </div>

      {/* <!-- Slider controls --> */}
      <button
        type="button"
        className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        data-carousel-prev=""
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
          <svg
            aria-hidden="true"
            className="w-6 h-6 text-white dark:text-gray-800"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
          <span className="sr-only">Previous</span>
        </span>
      </button>
      <button
        type="button"
        className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
        data-carousel-next=""
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
          <svg
            aria-hidden="true"
            className="w-6 h-6 text-white dark:text-gray-800"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            ></path>
          </svg>
          <span className="sr-only">Next</span>
        </span>
      </button>
    </div>
  );
};

export default Carousel;
