import { useInView } from "react-intersection-observer";

// Imgs
import Heading from "./Heading";
import danielle from "../imgs/danielle.webp";
import nicky from "../imgs/nicky.webp";
import tamara from "../imgs/tamara.webp";
import vanessa from "../imgs/vanessa.webp";
import caroline from "../imgs/caroline.webp";
import anna from "../imgs/anna.webp";
import rachael from "../imgs/rachael.webp";

const details = [
  {
    name: "Danielle",
    img: danielle,
    description: `As a Specialist Support Coordinator and Service Manager for the Support Coordination team at My Disability Partner, my primary objective is to deliver an exceptional service to the individuals we support. With 19 years in the disability sector, I bring a wealth of experience and knowledge in my daily support to our many participants, families and growing team. We are down to earth and approachable coordinators, with a focus on excellent communication and commitment to ensuring every participant has the best possible care. We look forward to assisting you with your NDIS journey.`,
  },
  {
    name: "Nicky",
    img: nicky,
    description:
      "For the past 8 years, I have been supporting individuals within the disability sector. As an experienced Support Coordinator, I bring a wealth of NDIS experience, ranging from addressing mental health challenges to assisting with physical disabilities and devising housing solutions for smooth transitions. I am honored to play a role in helping my participants realise their full potential and live their best lives, I look forward to the continued opportunity to make a meaningful impact in the lives of those I support.",
  },
  {
    name: "Tamara",
    img: tamara,
    description:
      "I began my career in the disability sector as a qualified and registered nutritionist. I work closely with my participants to achieve nutritional needs and working towards their health goals, focusing on a personalised approach and practical cooking classes. I joined the My Disability Partner team as a Support Coordinator, as I am passionate about a holistic approach to health. I support a range of participants, from accessing suitable accommodation and facilitating community transitions, to addressing and overcoming mental health challenges. Working within the NDIS aligns with my personal values, as I  support my participants to achieve their plan goals and improve quality of life.",
  },
  {
    name: "Vanessa",
    img: vanessa,
    description:
      "I have been working in the disability sector for the past 14 years, I have held diverse positions which has lead me to my current role as a Senior Support Coordinator and Recovery Coach. My roles have ranged from providing accommodation services to working directly with clients as a dedicated support worker, and I have now expanded my skill set by offering Psychosocial Recovery Coaching services. This role has enabled me to further support individuals dealing with mental health concerns by empowering them to develop coping strategies, resilience, and ultimately, enhance their quality of life. I am highly dedicated to all the participants and families I support; my strong communication is my overall strength, no challenge is too difficult to work through.",
  },
  {
    name: "Caroline",
    img: caroline,
    description:
      "As the Business Administration Manager and personal assistant to Danielle, Caroline brings a wealth of knowledge and experience to the team. Caroline has worked in the NDIS industry for over 5 years, so has an extensive knowledge of the processes involved. Caroline is dedicated to delivering a very high level of customer service as well as offering a lovely caring approach.",
  },
  {
    name: "Anna",
    img: anna,
    description:
      "For the past 8 years I have been in the disability industry, specialising in support coordination for over 5 years. I have devoted my professional career to serving individuals within the disability sector. I’ve developed a deep understanding of the unique challenges faced by those seeking assistance. My commitment goes beyond mere coordination. I am driven by a passion for creating meaningful and empowering experiences for each person I work with.",
  },
  {
    name: "Rachael",
    img: rachael,
    description:
      "I have been working within the health care industry for over 9 years, with a qualification as a Registered Nurse.  I have worked in many roles including Support work, Care coordinator, Care manager and Support Coordination. I have a deep passion for assisting people and their individual needs whilst ensuring they have the best person-centered care and quality of life. I strive to provide high level care to all of those I work with to enable they feel well supported",
  },
];

const Coordinators = () => {
  const { ref: myRef, inView: myElementIsVisible } = useInView({
    triggerOnce: true,
  });

  return (
    <section className="pt-24">
      <article className="mx-auto max-w-screen-2xl px-4 md:px-8">
        <Heading heading="Meet our Co-ordinations" />
        <section
          ref={myRef}
          className={`fade-in my-24 grid grid-cols-1 lg:grid-cols-2 gap-24 ${
            myElementIsVisible ? "appear" : ""
          }`}
        >
          {details.map((val, key) => {
            return (
              <article
                key={key}
                id="coordinators_container"
                className="flex flex-col md:flex-row gap-6 rounded-b-xl "
              >
                <img
                  src={val.img}
                  alt="img"
                  className="w-44 md:w-44 object-cover rounded-lg shadow-xl"
                />
                <div className="space-y-4 py-6 px-2 my-auto">
                  <h2 className="pb-3 text-2xl md:text-4xl font-bold border-b-4 border-primary">
                    {val.name}
                  </h2>
                  <p className="text-sm md:text-md lg:text-base">
                    {val.description}
                  </p>
                </div>
              </article>
            );
          })}
        </section>
      </article>
    </section>
  );
};

export default Coordinators;
